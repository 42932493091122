import request from "@/utils/request";

export function getImageCode() {
  return request.get("/customer/captchaImage");
}

export function getSendCode(data) {
  return request.get("/customer/sendVerifyCode/" + data.phoneNumber + "/" +data.code +"/"+ data.uuid);
}

export function getPhoneCode(data) {
  return request.get("/customer/sendVerifyCode/" + data.phoneNumber);
}

export function UserLogin(data,option) {
    return request.post("/customer/login",data,option);
}

export function getUserInfo() {
    return request.get("/customer/getInfo");
}

export function wxacode() {
  return request.get("/customer/get/wxacode");
}

export function wechatUserInfo(data) {
  return request.get("/customer/computer/wechat/login?uniqueIdentify="+data);
}

export function getIfLogin() {
    return request.get("/customer/is/login");
}

export function getPreview(type) {
    return request.get("/goods/banner/onlinePreview?type=" + type);
}
